import type { FeatureKey } from '~/types/auth'

export const useFeatureAccess = () => {
  const { data: features } = useQueryFeature.all()

  const hasFeature = (key: FeatureKey) => !!features.value?.[key]?.enabled

  return {
    hasFeature,
  }
}
